  :host {
    display: inline-block;
    color: var(--sl-color-neutral-700);
  }

  sl-icon {
    display: block;
    font-size: 1rem;
  }

  sl-skeleton {
    width: 4em;
  }
